/**
 * @namespace
 */
var site = site || {};
site.signin = site.signin || {};

(function($) {
  Drupal.behaviors.forgot_password = {
  attach: function(context) {
    /**
    * One-time call to collect specific RB Keys used for forget password.
    * @methodOf site.signin
    */
    
    site.signin.getRBKeys = function() {
      site.signin.rb = generic.rb("error_messages");
	  site.signin.forgotPasswordEmailNotFound = site.signin.rb.get('incorrect_pwremind');
	  //site.signin.forgotPasswordNoEmailProvided = site.signin.rb.get('session_pw_hint');
	  // TODO: put this in the RB 
	  site.signin.forgotPasswordNoEmailProvided = "Please enter your email address.";
      site.signin.forgotPasswordMigratedUser = site.signin.rb.get('migrated.mobile_account.signin');
      site.signin.passwordResetSend = site.signin.rb.get('signin_error.email_address');
    }

  /**
    * This method is used to set up the forget password functionality
    * on the site.
    * Takes the passed element in the DOM and gets the required form
    * nodes and places them within forgotPassArgs object.
    * site.signin.setForgetPassword() is then called if the param resetPassword
    * is set to true.
    * @param {Object} args
    * @param {Object} args.emailNode **REQUIRED** DOM element of either a
    * form element or wrapper element of the email.
    * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
    * password hint or error messaging if hint is not available.
    * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
    * forget password link.
    * @params {element} forget link node set on dom:load
    * @methodOf site.signin
  */
  site.signin.forgotPassword = function(args) {
    if ((args.emailNode.length > 1) || (!args.forgotPasswordLink) || (!args.errorListNode) ) {
      return null;
    }

    site.signin.getRBKeys();

    var errorListNode = args.errorListNode;
    var emailNode = args.emailNode;
    var forgotPasswordLink = args.forgotPasswordLink;
    var forgotPasswordNote = args.forgotPasswordNote;
    var forgotPasswordCopy = $("#lpw-text");
    var $passwordResetEmailNode = $('.js-password_reset_send__EMAIL_ADDRESS', context);
    // content may have been set on server side. If so, do not hide.
    if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length<1) {
        forgotPasswordCopy.hide();
    }

    if ($passwordResetEmailNode.length === 1) {
      emailNode.once().on('change', function() {
        var email = emailNode.val();
        $passwordResetEmailNode.val(email);
      });
    }

    forgotPasswordLink.on('click', function(evt) {
      evt.preventDefault();
        
      forgotPasswordCopy.show();
      var email = site.signin.getEmailAddress(emailNode);

      if (email.length < 1) {
        $('.error_messages').empty();
        $('.error_messages').append("<li class='s' id='signin_error.email_address.'>" + site.signin.forgotPasswordNoEmailProvided + "</li>");
            
        // remove errors from new user section div
        $('#new-account > .error_messages').empty();
        $('.messages').empty();
          return null;
      }
        
      if ($passwordResetEmailNode.length === 1) {
        var $errors = $('.error_messages', context);
        $errors.empty();
        $passwordResetEmailNode.val(emailNode.val());
        var $passwordResetForm = $('.sign-in-page__password_reset_send form', context);
        var Hash = generic.Hash();
        var param = Hash.queryToJson($passwordResetForm.serialize());
        _.forEach(param, function (value, key) {
            param[key] = decodeURIComponent(value);
        });
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [param],
          onBoth: function(r) {
            var messages = r.getMessages();
            if (messages) {
              $.each(messages, function(i, message) {
                if (message.key === "request_sent") {
                  var $passwordResetSendMsg = $('#passwordResetSendMsg', context);
                  var hasPasswordResetSendMsg = $passwordResetSendMsg.length;
                  hasPasswordResetSendMsg ? $passwordResetSendMsg.show() :
                    $errors.append('<li id=\'passwordResetSendMsg\'>' + site.signin.passwordResetSend + '</li>');
                }
              });
            }
          }
        });
      }
        
      // remove errors from new user section div
      $('#new-account > .error_messages').empty();
      return false;
    });
  };

  /**
    * This method is used to reset a users password by submitting a hidden form.
    * @param {email} the user's email address **REQUIRED**
    * @param {actionURL} the page URL of the reset page **REQUIRED**
    * **NOTE**: The error check for if an account exists is handled by the password
    * hint function. The reset is hidden inside the password hint function
    * so no duplicate error checking is needed here.
  */
  site.signin.initResetPassword = function(emailNode) {
    //have to initialise the link here because it isn't on the page until the pw hint method is invoked
    var email = site.signin.getEmailAddress(emailNode);
    var resetPassLink = $('#pwd-reset');
    if (resetPassLink) {
      resetPassLink.bind('click', function(evt) {
        evt.preventDefault();
        site.signin.requestPassword(email);
      });
    }};


  /**
    * This method is used to direct the user to registration.tmpl or password_request.tmpl.
    * The passed values are injected into the genric form before it is submitted.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {actionURL} action url used on user submit. **REQUIRED**
    * @param {returnURL} passed when an action is needed after the user
    * has gone to the next template page. **NOT REQUIRED**
    * Example case for returnURL is if the user goes through checkout and registers,
    * the returnURL is used to pass the viewbag action url to the registration page. Once
    * registration form is filled out, user will be brought to viewbag.
    * @methodOf site.signin
  */
  site.signin.submitHiddenSigninForm = function(args) {
    if (!args.actionURL || !site.signin.hiddenForm) {
      return null;
    }
    site.signin.hiddenForm.attr('action', args.actionURL);
    var hiddenEmailNode = $('.js-hidden-email');
    hiddenEmailNode.val(args.email);
    
    if (args.returnURL) {
      var hiddenReturnNode = $('.js-hidden-return');
      hiddenReturnNode.val(args.returnURL);
    }
    site.signin.hiddenForm.submit();
  };

  /**
    * This method is used to call site.signin.submitHiddenSigninForm by
    * passing the user's email used in the reset form submit.
    * @param {String} the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
 */
  site.signin.requestPassword = function(emailAddr) {
    site.signin.hiddenForm = $('.js-signin-hidden-form');
    if (site.signin.hiddenForm) {
      site.signin.submitHiddenSigninForm({
        email: emailAddr,
        actionURL: '/account/password_request.tmpl'
      });
    }
  }

  /**
    * This method is used to pull the user's email from either a form
    * input or container html tag wrapper (i.e. div, span, etc)
    * @param {String} emailNode the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
  */
  site.signin.getEmailAddress = function(emailNode) {
    if(!emailNode ) return null;
    return emailNode.val();
  }

  this.attached = true;
  
  },
  
  attached: false
};

})(jQuery);
